import React from 'react'
import { useEffect } from 'react';
import ApexCharts from 'apexcharts';

const StackedBarChartNew = ({ CountOfCaseID }) => {
    useEffect(() => {
      const options = {
        series: CountOfCaseID,
        chart: {
          type: 'bar',
          height: 100,
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        grid: {
          show: false,
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        colors: ['#073571','#074EAB', '#2689B5', '#3FAFE2', '#1B73B9', '#35BDFA'],
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: '100%',
            dataLabels: {

              total: {
                position:"top",
                enabled: true,
                offsetX: 20,
                style: {
                  fontSize: '12px',
                  fontWeight: 900,
                },
                formatter: function (val) {
                  return  parseFloat(val).toFixed(2) + '%';
                },
              },
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val,opts) {
            const seriesName = opts.w.globals.seriesNames[opts.seriesIndex];
            return seriesName + ": " + val + '%';
          },
          style: {
            fontSize: '10px',
            fontWeight: 'bold',
            colors: ['#fff']
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff'],
        },
        title: {
          text: '',
        },
        xaxis: {
          categories: ["Total"],
          labels: {
            show: false,
            formatter: function (val) {
              return val + '%';
            },
          },
          axisBorder: {
            show: false,
          },
        },
        yaxis: {
          title: {
            text: undefined,
          },
          labels: {
            show: false,
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + '%';
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          offsetX: 40,
          show: false,
        },
      };

      const chart = new ApexCharts(document.querySelector("#stacked-bar-chart-new"), options);
      chart.render();

      return () => {
        chart.destroy();
      };
    }, [CountOfCaseID]);

    return <div id="stacked-bar-chart-new" class="chart"></div>;
  }

export default StackedBarChartNew
