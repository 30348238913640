import React from 'react'
import { useEffect } from 'react';
import ApexCharts from 'apexcharts';

const BarChart = ({ id, horizontal, isFunnel, sorting, datalist = [] }) => {
  useEffect(() => {
    if (sorting) {
      datalist.sort((a, b) => b.count - a.count)
    }

    const dataItem = datalist.map((item) => item.count)
    const dataMonth = datalist.map((item) => item.name)
    const acknowledgedIndex = dataMonth.indexOf("Pending for reply by taxpayer");
    const barColors = dataMonth.map((name, index) => index === acknowledgedIndex ? '#FF5733' : '#35bdfa');




    const options = {
      series: [{
        name: 'Distinct Case ID',
        data: dataItem
      }],
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false
        }
      },

      colors: barColors,
      plotOptions: {
        bar: {
          horizontal: horizontal,
          columnWidth: '55%',
          endingShape: 'rounded',
          distributed: true
        },
      },
      dataLabels: {
        enabled: true
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      title: {
        text: '',
      },
      xaxis: {
        categories: dataMonth,
      },
      yaxis: {
        title: {
          text: ''
        }
      },
      fill: {
        opacity: 1
      },
      legend: {
        show: false
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val
          }
        }
      }
    };

    const chart = new ApexCharts(document.querySelector(`#${id}`), options);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [datalist]);

  return <div id={`${id}`} class="chart"></div>;
};

export default BarChart
