import React, { useEffect, useRef, useState } from "react";
import MainLayout from "../Layout/MainLayout";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Rating from "@mui/material/Rating";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import JsonData from "../app/data/advancedNotices.json";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useOktaAuth } from "@okta/okta-react";
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(2, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const StyledRating = styled(Rating)(({ theme }) => ({
  "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
    color: theme.palette.action.disabled,
  },
}));

const customIcons = {
  1: {
    icon: (
      <SentimentVeryDissatisfiedIcon sx={{ fontSize: "34px" }} color="error" />
    ),
    label: "Very Dissatisfied",
  },
  2: {
    icon: <SentimentDissatisfiedIcon sx={{ fontSize: "34px" }} color="error" />,
    label: "Dissatisfied",
  },
  3: {
    icon: <SentimentSatisfiedIcon sx={{ fontSize: "34px" }} color="warning" />,
    label: "Neutral",
  },
  4: {
    icon: (
      <SentimentSatisfiedAltIcon sx={{ fontSize: "34px" }} color="success" />
    ),
    label: "Satisfied",
  },
  5: {
    icon: (
      <SentimentVerySatisfiedIcon sx={{ fontSize: "34px" }} color="success" />
    ),
    label: "Very Satisfied",
  },
};

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

const Registed = () => {
  const [validated, setValidated] = useState(false);
  const [userType, setuserType] = useState("Existing");
  const [isLoading, setIsloading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [State, setState] = useState("");
  const [Comapny, setCompany] = useState("");
  const [Password, setPassword] = useState("");

  const [LoginID, setLoginID] = useState("");
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState();
  const [accessDenaied, setaccessDenaied] = useState(true)

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {
    // console.log("User Login Success");		 
      setUserInfo(authState.idToken.claims);
      const fetchData = authState.idToken.claims
      const email = fetchData.email
      console.log(email)
      if(email === 'support@datadekho.in'){
        setaccessDenaied(false)
      }
      else{
       
        setaccessDenaied(true)
      }
    }
  }, [authState, oktaAuth]);



  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if(Comapny !== '' &&  State !== "" && LoginID !== "" && Password !==""  ){
      setValidated(false);
      setIsloading(true)
      setTimeout(() => {
        setIsloading(false)
        setSuccess(true)
      }, 5000);
    }
    else{
      setValidated(true);

    }
  };

  return (
    <div id="normal-registerd" style={{ overflow: "hidden" }}>
      <Box className="mainLayout" sx={{ display: "flex", overflow: "hidden" }}>
        <MainLayout dataItems={JsonData} setCompany={setCompany} />

        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <DrawerHeader />
          <DrawerHeader style={{ minHeight: "0px", paddingTop: "3px" }} />

          <div id="mainHome" style={{ overflowY: "auto" }}>
          {!accessDenaied ? (
              <>

            {success && (
              <div
                style={{ width: "400px" }}
                class="alert alert-success"
                role="alert"
              >
                Successfully Submitted for GST Notices and Orders
              </div>
            )}

            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Form.Group as={Col} md="4" controlId="validationCustom02">
                  <Form.Label>Name of the Organisation*</Form.Label>

                 
                  <Form.Control
                    className="validationCustomLoginId"
                    autoComplete="off"
                    required
                    type="text"
                    placeholder="Name of the Organisation"
                    value={Comapny}
                    onChange={(e) => setCompany(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                  Name of the Organisation is required *
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} md="4" controlId="validationCustom02">
                  <Form.Label>Select State *</Form.Label>

                  <Form.Select
                    required
                    value={State}
                    onChange={(e) => setState(e.target.value)}
                  >
                    <option value="">Select State</option>

                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                    <option value="Assam">Assam</option>
                    <option value="Bihar">Bihar</option>
                    <option value="Chhattisgarh">Chhattisgarh</option>
                    <option value="Goa">Goa</option>
                    <option value="Gujarat">Gujarat</option>
                    <option value="Haryana">Haryana</option>
                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                    <option value="Jharkhand">Jharkhand</option>
                    <option value="Karnataka">Karnataka</option>
                    <option value="Kerala">Kerala</option>
                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                    <option value="Maharashtra">Maharashtra</option>
                    <option value="Manipur">Manipur</option>
                    <option value="Meghalaya">Meghalaya</option>
                    <option value="Mizoram">Mizoram</option>
                    <option value="Nagaland">Nagaland</option>
                    <option value="Odisha">Odisha</option>
                    <option value="Punjab">Punjab</option>
                    <option value="Rajasthan">Rajasthan</option>
                    <option value="Sikkim">Sikkim</option>
                    <option value="Tamil Nadu">Tamil Nadu</option>
                    <option value="Telangana">Telangana</option>
                    <option value="Tripura">Tripura</option>
                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                    <option value="Uttarakhand">Uttarakhand</option>
                    <option value="West Bengal">West Bengal</option>
                    <option value="Andaman and Nicobar Islands">
                      Andaman and Nicobar Islands
                    </option>
                    <option value="Chandigarh">Chandigarh</option>
                    <option value="Dadra and Nagar Haveli and Daman and Diu">
                      Dadra and Nagar Haveli and Daman and Diu
                    </option>
                    <option value="Lakshadweep">Lakshadweep</option>
                    <option value="Delhi">Delhi</option>
                    <option value="Puducherry">Puducherry</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    User Type is required *
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} md="4" controlId="validationCustomLoginId">
                  <Form.Label>GST portal Login ID*</Form.Label>

                  <Form.Control
                    className="validationCustomLoginId"
                    autoComplete="off"
                    required
                    type="text"
                    placeholder="Portal Login ID"
                    value={LoginID}
                    onChange={(e) => setLoginID(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                  Portal Login ID is required *
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} md="4" controlId="validationCustomPass">
                  <Form.Label>GST Portal Password *</Form.Label>

                  <Form.Control
                    className="validationCustom01Pass"
                    autoComplete="off"
                    required
                    type="Password"
                    placeholder="GST Portal Password"
                    value={Password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                  GST Portal Password is required *
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <div
                style={{
                  gridGap: "10px",
                  display: "flex",
                  marginTop: "1rem",
                }}
                className="mb-3"
              >
                <Button
                  disabled={isLoading}
                  style={{ width: "auto" }}
                  className="submit_btn"
                  type="submit"
                >
                  {isLoading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span style={{ paddingLeft: "10px" }}>
                        Submiting
                      </span>
                    </>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </div>
            </Form>
            </>
          ): (
            <>
            <div class="alert alert-danger" role="alert">
            Access denied. You don't have permission to access this page. Please contact your administrator.                </div>
            </>
          )}
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default Registed;
