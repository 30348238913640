import React from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";


const Search = ({dataItems,setCompany}) => {


  const companyName = dataItems.map((item) => item.Company)
  const uniqueCompany = [...new Set(companyName)]

  const companyItem = uniqueCompany.map((item) =>{
    return{
      name:item
    }
  })


  const items = companyItem;

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log(string, results);
    if(results.length === 0){
      const data = results.map((item) => item.name)
      setCompany(data[0] || "")
  
    }
  };

  const handleOnHover = (result) => {
    // the item hovered
    console.log(result);
  };

  const handleOnSelect = (item) => {
    // the item selected
    console.log(item.name);
    setCompany([item.name])
  };

  const handleOnFocus = () => {
    console.log("Focused");
  };

 const handleClear = () =>{
  setCompany("")

 }
  const formatResult = (item) => {
    return (
      <>
      
        <span style={{ display: "block", textAlign: "left" }}>
           {item.name}
        </span>
      </>
    );
  };
  return (
    <div>
      <div style={{ width: 400 }}>
        <ReactSearchAutocomplete
          items={items}
          onSearch={handleOnSearch}
          onHover={handleOnHover}
          onSelect={handleOnSelect}
          onFocus={handleOnFocus}
          formatResult={formatResult}
          placeholder="Search by Company name"
          onClear={handleClear}
        />
      </div>
    </div>
  );
};

export default Search;
