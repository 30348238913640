import React, { useState } from 'react'
import SideNavbar from '../nav/SideNavbar'
import Box from '@mui/material/Box';

const MainLayout = ({dataItems, setCompany}) => {

  return (
    <Box>
        <SideNavbar dataItems={dataItems} setCompany={setCompany}/>
    </Box>
  )
}

export default MainLayout
