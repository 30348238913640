import React, { useEffect, useState } from "react";
import { styled, useTheme, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link, useLocation } from "react-router-dom";
import { useOktaAuth } from '@okta/okta-react';
import {
  FaCog,
  FaSignInAlt,
  FaChartBar,
  FaChartLine,
  FaRegCommentDots,
  FaUsersCog,
  FaDatabase,
  FaThLarge,
  FaCogs,
  FaFileDownload,
  FaUserPlus
} from "react-icons/fa";
import Topnavbar from "./Topnavbar";
import profile from "../assets/img/User.png";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Getusers from "../auth/Getusers";
import axios from "axios";
import logo from "../assets/img/DataDekho-White.png";
import logo2 from "../assets/img/DataDekho-64.png";

const SideNavbar = ({ dataItems,setCompany }) => {
  const [open, setOpen] = useState(false);
  const [openDialog, setopenDialog] = useState(false);
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState();
  const [MenuItems,setMenuItems] =useState(
    [
      { menu: "Overview", route: "/", icon: <FaChartBar  className="colorCss" fontSize={24} /> },
      { menu: "Dataroom", route: "/dataroom", icon: <FaDatabase  className='colorCss' fontSize={24} /> },  
    ]
  )

 

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {
    // console.log("User Login Success");		 
      setUserInfo(authState.idToken.claims);
      const fetchData = authState.idToken.claims
      const email = fetchData.email
      if(email === 'support@datadekho.in'){
        setMenuItems(
          [
            { menu: "Overview", route: "/", icon: <FaChartBar  className="colorCss" fontSize={24} /> },
            { menu: "Dataroom", route: "/dataroom", icon: <FaDatabase  className='colorCss' fontSize={24} /> },
            { menu: "Download Notices", route: "/Download", icon: <FaFileDownload  className='colorCss' fontSize={24} /> },
            { menu: "Add GST Info", route: "/addnewgst", icon: <FaUserPlus  className='colorCss' fontSize={24} /> },
        
          ]
        )
      }
      else{
        setMenuItems(
          [
            { menu: "Overview", route: "/", icon: <FaChartBar  className="colorCss" fontSize={24} /> },
            { menu: "Dataroom", route: "/dataroom", icon: <FaDatabase  className='colorCss' fontSize={24} /> },
        
          ]
        )
      }
    }
  }, [authState, oktaAuth]);


  const drawerWidth = 300;

  const logout = async () => {
    await oktaAuth.signOut();
  };

  const { pathname } = useLocation();

  
  const [profileImage, setProfileImage] = useState(() => {
    const storedImage = localStorage.getItem("profileImage");
    return storedImage !== null ? storedImage : profile;
  });

  useEffect(() => {
    localStorage.setItem("profileImage", profileImage);
  }, [profileImage]);

  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];
    if (selectedImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(selectedImage);
    }
  };

  

  // useEffect(() => {
  //   setColorCode(colorCode);
  //   setBGColor(colorCode.side_nav);
  //   setIconColors(colorCode.icon_color);
  //   if (saved) {
  //     console.log("saved");
  //   }
  // }, [colorCodeDataItem, saved]);

  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    background: `#2076BA`,
    color: "white",
    overflowX: "hidden",
    borderRight: "1px dotted rgba(86,88,105, 1) !important",
    "&& .Mui-selected": {
      outline: "0px",
      border: "0px",
      cursor: "pointer",
      userSelect: "none",
      verticalAlign: "middle",
      appearance: "none",
      display: "flex",
      flexGrow: "1",
      justifyContent: "flex-start",
      alignItems: "center",
      textDecoration: "none",
      boxSizing: "border-box",
      textAlign: "left",
      transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      position: "relative",
      textTransform: "capitalize",
      color: "rgb(32, 101, 209);",
      borderRadius: "8px !important",
      backgroundColor: "#0000a0",
      fontWeight: "700 !important",
    },
    "&& .logoutLayout": {
      left: "20px !important",
      position: "Absolute",
      bottom: "20px"
    },
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: `#2076BA`,
    color: "white",
    overflowX: "hidden",
    borderRight: "1px dotted rgba(86,88,105, 1) !important",
    padding: "0px",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 20px)`,
    },
    "&& .Mui-selected": {
      borderRadius: "8px !important",
    },
    "&& .logoutLayout": {
      left: "8px !important",
      position: "Absolute",
      bottom: "20px"
    },
  });

  const openbox = (theme) => ({
    left: "286px !important",
  });

  const closebox = (theme) => ({
    left: "72px !important",
  });

  const listTogglebox = (theme) => ({
    padding: "0 !important",
    paddingTop: "2rem !important",
    padding: "1rem .5rem 0rem !important",
    borderRadius: "20px",
  });

  const listTogglebox1 = (theme) => ({
    display: "none !important",
  });

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: theme.spacing(4, 2.6),
    ...theme.mixins.toolbar,
  }));

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "noWrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
      "& .navButtonToggle": openbox(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
      "& .navButtonToggle": closebox(theme),
      "& .listToggle": listTogglebox(theme),
      "& .listToggle1": listTogglebox1(theme),
    }),
  }));

  return (
    <div id="sidenavBarnew">
      <Box sx={{ display: "flex" }}>
        <Topnavbar setCompany={setCompany} dataItems={dataItems} open={open}/>

        <Drawer className="sidenavBarstyle" variant="permanent" open={open}>
          <IconButton
            className="navButtonToggle bgColorNew borderColorNew"
            onClick={() => setOpen(!open)}
          >
            {open === false ? (
              <ChevronRightIcon className="colorCss" />
            ) : (
              <ChevronLeftIcon className="colorCss" />
            )}
          </IconButton>
          {open && (
            <>
              <div style={{ position: "relative", marginTop: "1.2rem" ,justifyItems:"start",display:"grid",paddingLeft:"1rem",height:"100px"}}>
               
                <img src={logo} alt="logo" width={180}/>
              </div>
              
            </>
          )}

          {!open && (
            <>
              <div
                style={{
                  position: "relative",
                  marginTop: "0px",
                  height: "100px",
                }}
              >
                <div
                  style={{
                    paddingTop: "2rem",
                    marginTop: "0px",
                    paddingBottom: "1rem",
                  }}
                >
                  <img
                    style={{
                      position: "absolute",
                      margin: "auto",
                      display: "block",
                      left: "0",
                      right: "0",
                      top: "20%",
                      borderRadius: "100%",
                      width: "45px",
                    }}
                    src={logo2}
                    alt="Profile"
                  />
                </div>
              </div>
            </>
          )}

          <List
            className="listToggle"
            style={{
              padding: "1.3rem",
              display: "grid",
              gridGap: "1rem",
              paddingTop: "3rem",
              alignContent: "baseline",
              alignItems: "baseline"
            }}
          >
            {MenuItems.map((menuItem, index) => (
              <Link
                key={index}
                style={{ color: "white" }}
                to={`${menuItem.route}`}
              >
                <ListItem
                  className="itemsmenulist colorCss"
                  selected={menuItem.route === pathname}
                  disablePadding
                  sx={{ display: "block" }}
                  title={menuItem.menu}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 53,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                      className="colorCss"
                    >
                      {menuItem.icon}
                    </ListItemIcon>
                    <ListItemText
                      className="listToggle1"
                      primary={menuItem.menu}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              </Link>
            ))}

            <div className="logoutLayout">
              <ListItem
                className="itemsmenulist itemsmenulist2"
                title="Logout"
                disablePadding
                sx={{ display: "block" }}
                onClick={() => setopenDialog(!openDialog)}
              >
                <ListItemButton
                  sx={{
                    minHeight: 53,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <FaSignInAlt className="colorCss" fontSize={20} />
                  </ListItemIcon>
                  <ListItemText
                    className="listToggle1 colorCss"
                    primary="Logout"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </div>
          </List>
        </Drawer>

        <Dialog
          open={openDialog}
          onClose={() => setopenDialog(!openDialog)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          id="logoutPopup"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to logout ?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Please confirm your decision.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setopenDialog(!openDialog)}>
              No
            </Button>
            <Button onClick={logout}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </div>
  );
};

export default SideNavbar;
