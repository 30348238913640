import React, { useEffect } from 'react';
import ApexCharts from 'apexcharts';

const StackedBarChart = ({ result, resultTypeOfNoticeYear }) => {
  useEffect(() => {
    // Extract distinct years
    const years = resultTypeOfNoticeYear.map((item) => item.year);
    const distinctYears = [...new Set(years)];

    // Sort years in descending order
    const sortedYears = distinctYears.sort((a, b) => b - a);
    const yearsAsNumbers = sortedYears.map(year => parseInt(year));

    // Transform result data into series format expected by ApexCharts
    // const series = result.map(item => ({
    //   name: item.name,
    //   data: item.data
    // }));

    const series = result.map(item => ({
     
      name: item.name,
      data: yearsAsNumbers.map(year => {
        const foundData = item.data.find(data => data.year === year);
        return foundData !== undefined ? foundData.count : 0; // Assuming value is the data point
      })
    }));


    

    // ApexCharts configuration options
    const options = {
      series: series, // Extracting only the data arrays
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: false
        }
      },
      colors: ['#073571', '#074EAB', '#2689B5', '#3FAFE2', '#1B73B9', '#35BDFA'],
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            total: {
              enabled: true,
              offsetX: 15,
              style: {
                fontSize: '12px',
                fontWeight: 900,
              },
            },
          },
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff'],
      },
      title: {
        text: '',
      },
      xaxis: {
        categories: sortedYears,
        labels: {
          formatter: function (val) {
            return val;
          },
        },
      },
      yaxis: {
        title: {
          text: undefined,
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 10,
      },
    };

    // Create new ApexCharts instance
    const chart = new ApexCharts(document.querySelector("#chart"), options);

    // Render the chart
    chart.render();

    // Cleanup on component unmount
    return () => {
      chart.destroy();
    };
  }, [result, resultTypeOfNoticeYear]); // Ensure dependencies are correctly set

  return <div id="chart"></div>;
};

export default StackedBarChart;
