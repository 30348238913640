import React from "react";
import imgsData from '../../assets/img/DataDekho-64.png'
import  '../../assets/css/preloading.css'

const Loading = () => {
  return (
    <div>
      <div class="wrapper">
        <div id="loader-wrapper">
          <div id="loader">
            <div class="loader-outter"></div>
            <div class="loader-inner"></div>

            <div class="indicator">
              <img
                src={imgsData}
                alt="Logo"
                width="15px"
                height="15px"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
