import React from "react";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import Button from "@mui/material/Button";
import { FaDownload } from "react-icons/fa";

const ReportGenerate = ({ excelData, sheetName, fileName, transformData = () => {} }) => {


  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8";
  const fileExtension = ".xlsx";
  var wscols = [
    { wch: 50 },
    { wch: 40 },
    { wch: 20 },
    { wch: 30 },
    { wch: 30 },
    { wch: 25 },
    { wch: 60 },
    { wch: 60 },
    { wch: 60 },
    { wch: 60 },
    { wch: 60 },
    { wch: 60 },
    { wch: 60 },
    { wch: 60 },
    { wch: 60 },
  ];
  const addStyles = (workSheet) => {

    Object.entries(workSheet)
    .filter(([k, v]) => k.charAt(0) === "A")
    .forEach(([k, v]) => {
      if (v.v === "Type of Notice/Order") {
        v.s = {
          fill: {
            fgColor: { rgb: "FFFFFF" },
          },
          alignment: {
            vertical: "center",
            horizontal: "center",
          },
          font: {
            bold: false,
          }
        };
      } else {
        v.s = {
          alignment: {
            vertical: "center",
            horizontal: "center",
            wrapText:true
          },
          width: {
            auto: true,
          },
          height:{
            auto:true
          } ,
          font: {
            bold: false,
          }      
        };
      }
    });

    Object.entries(workSheet)
    .filter(([k, v]) => ['B', 'C', 'D', 'E', 'F', 'G', 'H'].includes(k.charAt(0)))
    .forEach(([k, v]) => {
      v.s = {
        alignment: {
          vertical: "center",
          horizontal: "center",
          wrapText:true
        },
        width: {
          auto: true,
        },
        height:{
          auto:true
        }
      };
    });

  };

  const excelDataLength = excelData.length;
  const rowHeight = Array(excelDataLength)
    .fill()
    .map((item) => ({ hpt: 30 }));

  const exportTOExcel = async () => {
    // console.log("excelData")
    // console.log(excelData)

  const transformedData = excelData;
    const ws = XLSX.utils.json_to_sheet(transformedData); 

    const range = ws["!ref"];
    ws["!autofilter"] = { ref: range };
    addStyles(ws);
    ws["!cols"] = wscols;
    ws["!rows"] = rowHeight;
    const wb = { Sheets: { [`${sheetName}`]: ws }, SheetNames: [`${sheetName}`] };
    const exelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([exelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <div style={{borderRadius: '4px'}}>
      <Button    
        style={{ backgroundColor: "#eee", fontSize: "14px", color: "#fff", padding: "5px", borderRadius:"6px",width:"40px",height:"50px"}}
        onClick={(e) => exportTOExcel(fileName)}
        className="export-button"
        title="Export in Excel"
      >
      <FaDownload style={{color:"#19738D",fontSize:"20px"}}/>
        
      </Button>
    </div>
  );
};

export default ReportGenerate;