import React, { Component } from "react";
import { withOktaAuth } from "@okta/okta-react";
import bgimage from "../assets/img/laptop-with-graph-screen-that-says-29-it.jpg";
import logo from "../assets/img/DataDekho-White.png";
import Home from '../pages/Home';

import "bootstrap/dist/css/bootstrap.min.css";


export default withOktaAuth(
  class SigninWidget extends Component {
    constructor(props) {
      super(props);
      this.login = this.login.bind(this);
      this.logout = this.logout.bind(this);
    }

    async login() {
      await this.props.oktaAuth.signInWithRedirect();
    }

    async logout() {
      await this.props.oktaAuth.signOut();
    }

    render() {
      let body = null;
      if (this.props.authState?.isAuthenticated) {
        body = (
          <div className="Buttons">
            <Home/>
          </div>
        );
      } else {
        body = (
          <div className="Buttons">
            <div className="loginPage">
              <div className="login_body_content">
                <div className="bg-white dark:bg-gray-900 bgColor">
                  <div  className="login_body_grid">
                    <div
                      style={{ backgroundImage: `linear-gradient(to right, rgb(53,189,250,0.2) , rgb(53,189,250,0.6)), url(${bgimage})` }}
                      className="hidden bg-cover lg:block lg:w-2/3 login_bg_image"
                    >
                      <div  style={{position:"relative",height:"100%"}} className="login_info">
                        <div>
                        <span style={{position:"absolute",left:"2%",top:"5%"}}>
                            <img width={200} src={logo} alt="img" />
                          </span>

                          <div
                            style={{
                              width: "80%",
                              display: "block",
                              background: "rgb(53,189,250,0.9)",
                              height: "auto",
                              padding: "1rem",
                              paddingTop: "2rem",
                              paddingBottom: "2rem",
                              position:"absolute",
                              bottom:"4%",
                              left:"4%",
                              right:"0px",
                              borderRadius:"10px"
                            }}
                          >
                            <div
                              style={{
                                display: "grid",
                                justifyContent: "start",
                              }}
                              className="text-4xl font-bold text-white"
                            >
                              <h1 style={{ fontSize: "34px",fontWeight:"700" }}>
                                Welcome to Notice Dekho
                              </h1>
                            </div>
                            <span
                              style={{
                                color: "white",
                                fontSize: "20px",
                                textAlign: "left",
                                display: "block",
                                fontWeight:"normal",
                                lineHeight:1.9
                              }}
                            >
                              Notice Dekho helps you manage all your tax notices
                              in one dashboard. You don't need to log in and
                              manually download PDFs anymore. Now you can easily
                              keep track of any new tax notices as they arrive.
                              Access to this system is limited - please contact{" "}
                              <a
                                rel="noreferrer"
                                target={"_blank"}
                                style={{ color: "black" }}
                                href="mailto:support@datadekho.in"
                              >
                                <b style={{ color: "#2076BA" }}>
                                  support@datadekho.in
                                </b>
                              </a>{" "}
                              for details.
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="right_grid_align bgColor">
                      <div className="flex-1">
                        <div className="text-center">
                          <span className="text-4xl font-bold text-center text-gray-700 dark:text-white MarsLogo">
                            <img width={200} src={logo} alt="img" />
                          </span>

                          <p className="left_grid_text left_margin loginText1 formLableText1">
                            Sign in to access your account
                          </p>
                        </div>

                        <div className="mt-8">
                          <div className="mt-6">
                            <button style={{background:"#35BDFA"}} className="btn_login" onClick={this.login}>
                              Sign in
                            </button>
                          </div>
                        </div>

                        <div
                          style={{
                            width: "70%",
                            paddingTop: "1rem",
                            display: "block",
                            margin: "auto",
                          }}
                        >
                          <span
                            id="Login_herf"
                            style={{
                              color: "white",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <a
                              rel="noreferrer"
                              target={"_blank"}
                              className="loginText1 formLableText1"
                              style={{ textDecoration: "none" }}
                              href="mailto:support@datadekho.in?subject=Need Access to the Lab Analytics BI Portal - Pilot Applications!"
                            >
                              {" "}
                              Need Access?
                            </a>

                            <a
                              rel="noreferrer"
                              target={"_blank"}
                              className="loginText1 formLableText1"
                              style={{ textDecoration: "none" }}
                              href="mailto:support@datadekho.in?subject=Need Help On Sign in On Lab Analytics BI Portal - Pilot Applications!"
                            >
                              {" "}
                              Need Help?
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }

      return <div className="App">{body}</div>;
    }
  }
);
