import  React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import dataJson from '../data/Notification.json'
import dataJson1 from '../data/advancedNotices.json'
import user from "../data/user.json";
import MailIcon from "@mui/icons-material/Mail";
import { Avatar, Badge } from "@mui/material";
import { useOktaAuth } from "@okta/okta-react";

export default function AnchorTemporaryDrawer() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState();
  const [userData,setuserData] = useState(user)

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {
    // console.log("User Login Success");		 
      setUserInfo(authState.idToken.claims);
      const fetchData = authState.idToken.claims
      const email = fetchData.email
      if(email === 'support@datadekho.in'){
        setNotificationsData(dataJson1)
      }
      else{
        const targetUser = userData.find(user => user.email === email);
        if(targetUser){
          const filteredNotices = dataJson.filter(notice =>
            targetUser.company.includes(notice.Company) &&
            targetUser.state.includes(`${notice.Company}_${notice.state.replace(/\s+/g, '_')}`)
        );
        console.log(filteredNotices)
           setNotificationsData(filteredNotices)
        }
        else{
          setNotificationsData(dataJson)
  
        }
      }
    }
  }, [authState, oktaAuth]);
const [NotificationsData,setNotificationsData] = useState(dataJson)
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  

  const Notifications = NotificationsData.filter((item) => item.Notification === "new")

 

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <div
            style={{ cursor: "pointer" }}
            onClick={toggleDrawer(anchor, true)}
          >
            <Badge badgeContent={Notifications.length} color="primary">
              <MailIcon color="action" />
            </Badge>
          </div>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            <div
              style={{
                width: "400px",
                backgroundColor: "#eee",
                height: "100%",
                overflow:"hidden",
                overflowY:"auto"
              }}
              id="notfication-show-panel"
            >
              <span
                style={{
                  backgroundColor: "#2076BA",
                  width: "100%",
                  display: "block",
                  position:"sticky",
                  top:"0",
                  zIndex:"999"
                }}
              >
                <span
                  style={{
                    padding: "1rem",
                    color: "white",
                    display: "block",
                    fontWeight: "600",
                    fontSize: "20px",
                  }}
                >
                  Notifications
                </span>
              </span>

              {Notifications &&
                Notifications.map((item, index) => {
                 return(
                  <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    padding: "10px",
                    background: "white",
                    marginBottom: "5px",
                  }}
                  key={index}
                >
                  <Avatar sx={{background:"#2076BA"}}>

                  {item.Company.charAt(0)}
                  </Avatar>
                  <span style={{ display: "grid",position:"relative",width:"100%" }}>
                    <span style={{fontWeight:"600"}}>{item.Company}_{item.state}</span>
                    <span>{item['Case ID']}</span>
                    <span style={{fontSize:"12px",color:"#3d3d3d"}}>{item['Date of Issuance']}</span>

                    <span style={{position:"absolute",right:"10px",background: item.NoticeType === "Normal" ? "#073571" :"#3FAFE2",color:"white",padding:"5px",borderRadius:"6px",fontSize:"12px"}}>{item.NoticeType}</span>

                  </span>
                </div>
                 )
                })}
            </div>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
