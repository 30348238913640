import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Getusers from "../auth/Getusers";
import Search from "../app/Search/Search";
import { Avatar, Badge } from "@mui/material";
import MailIcon from '@mui/icons-material/Mail';
import Notification from "../app/drawer/Notification";

const drawerWidth = 300;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(!open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${84}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }),
}));

const Topnavbar = ({dataItems,open,setCompany}) => {

 

  

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        style={{ backgroundColor: "white", boxShadow: "none", zIndex: "1" }}
        open={open}
      >
        <Toolbar
          style={{
            justifyContent: "space-between",
            paddingTop: "20px",
            alignItems: "center",
            paddingBottom: "20px",
            height: "72px",
            background: "#f8f9fa",
          }}
        >
          <Typography
            variant="h6"
            style={{
              color: "#253978",
              fontSize: "24px",
              fontWeight: "700",
              width: "100%",
              textAlign: "left",
              display: "flex",
              gap: "10px",
              alignItems: "center",
              alignContent: "center",
            }}
            noWrap
            component="div"
          >
            Welcome Back, <Getusers className="NameHeader" firstName={true} />
          </Typography>

          <div
            style={{
              width: "100%",
              gridGap: "3rem",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <Search dataItems={dataItems} setCompany={setCompany}/>

           

            <div>
              <Notification/>
            </div>

            <div>
              <Avatar sx={{ background: "#2076BA" }}>
                  <Getusers trim={true}/>
              </Avatar>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Topnavbar;
